import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../../components/Layout/Layout"
import HomeLayout from "../../../components/HomeLayout/HomeLayout"
import './photos.scss'
import CoolLightbox from "../../../components/LightBox/CoolLightBox"
const F = () => {
  const [isOpen, setOpen] = useState(false)
  const [currentImageIndex, setCurrentIndex] = useState(0)

  const data = useStaticQuery(graphql`
    query {
      F: allFile(
        sort: { order: ASC, fields: name }
        filter: { sourceInstanceName: { eq: "F" } }
      ) {
        nodes {
          id
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  var images = []
  data.F.nodes.map(img => {
    var src = img.childImageSharp.fluid.src
    images.push({
      src,
    })
  })

  return (
    <Layout>
    <HomeLayout>
        <>
          {data.F.nodes.map((img, index) => {
            return (
              <div
                onClick={() => {
                  setCurrentIndex(index)
                  setOpen(true)
                }}
                className="photos"
                key={index}
              >
                <Img fluid={{...img.childImageSharp.fluid , aspectRatio: 1}} alt="F_Images" />
              </div>
            )
          })}
          <CoolLightbox
            images={images}
            setCurrentIndex={setCurrentIndex}
            currentImageIndex={currentImageIndex}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
          />
        </>
        
        </HomeLayout>
        </Layout>
  )
}
export default F

